import React, { useEffect, useState } from "react";
import "./SeasonStanding.css";
import TeamSeason from "../components/TeamSeason/TeamSeason";

function SeasonStanding() {
  const [teams, setTeams] = useState([]);
  const [aixTeam, setAixTeam] = useState(null);

  useEffect(() => {
    fetchAndParseXML("/api/season-standing");
  }, []);

  const fetchAndParseXML = async (url) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      setTeams(data.HandballDocument.standing.item);
      setAixTeam(
        data.HandballDocument.standing.item.findIndex(
          (team) => team.teamName.trim().toLowerCase() === "aix",
        ),
      );
    } catch (error) {
      console.error("Failed to fetch or parse XML:", error);
    }
  };

  return (
    <div className="season-standing-container">
      {teams.length > 0 ? (
        <>
          <div className="team-row header">
            <div className="team-rank"></div>
            <div className="team-logo"></div>
            <div className="team-name"></div>
            <div className="team-points">pts</div>
            <div className="team-matches">mj</div>
            <div className="team-victories">vict.</div>
            <div className="team-draws">nul</div>
            <div className="team-defeats">déf.</div>
            <div className="team-goals-for">buts pour</div>
            <div className="team-goals-against">buts contre</div>
            <div className="team-goal-diff">goal avg</div>
          </div>
          {teams.map((team, index) => {
            if (index < aixTeam - 1 || index > aixTeam + 1) {
              return null;
            }
            return (
              <TeamSeason key={index} team={team} isAix={index === aixTeam} />
            );
          })}
        </>
      ) : (
        <p>Loading standings or data not available...</p>
      )}
    </div>
  );
}

export default SeasonStanding;
