import React, { useEffect, useState } from "react";
import PastGame from "../components/PastGame/PastGame";
import ReloadButton from "../components/ReloadButton/ReloadButton";

const lastGameEndpoint = "/api/last-game";
function LastGame() {
  const [data, setData] = useState(null);
  const [showReload, setShowReload] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowReload(true);
    }, 5000); // Show reload button after 5 seconds

    fetchAndParseXML(lastGameEndpoint);

    return () => clearTimeout(timer); // Cleanup timeout on component unmount
  }, []);

  const fetchAndParseXML = async (url) => {
    setShowReload(false); // Hide reload button when starting a new fetch
    try {
      const response = await fetch(url);
      const data = await response.json();
      // Assuming the structure of the data is consistent with what you've provided
      setData(data.HandballDocument.calendar.item);
    } catch (error) {
      console.error("Failed to fetch or parse XML:", error);
      setData(null);
    }
  };

  const handleReload = () => {
    setData(null); // Clear current data
    fetchAndParseXML(lastGameEndpoint); // Re-fetch data
  };

  return (
    <>
      {data ? (
        <PastGame data={data} />
      ) : (
        <ReloadButton showReload={showReload} handleReload={handleReload} />
      )}
    </>
  );
}

export default LastGame;
