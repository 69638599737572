import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LastGame from "./pages/LastGame";
import LiveChannel from "./pages/LiveChannel";
import NextGame from "./pages/NextGame";
import "./App.css";
import SeasonCalendar from "./pages/SeasonCalendar";
import PlayerDetails from "./pages/PlayerDetails";
import SeasonStanding from "./pages/SeasonStanding";
// Import other components similarly

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/last-game" element={<LastGame />} />
        <Route path="/live-channel" element={<LiveChannel />} />
        <Route path="/next-game" element={<NextGame />} />
        <Route path="/season-calendar" element={<SeasonCalendar />} />
        <Route path="/player-details/:playerSlug" element={<PlayerDetails />} />
        <Route path="/season-standing" element={<SeasonStanding />} />
        <Route path="*" element={<Navigate to="/last-game" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
