import React from "react";
import "./Percentage.css"; // Make sure to create this CSS file for styling

function PlayerCard({ percentage, children }) {
  console.log("percentage", percentage);
  return (
    <div className="circle-wrap">
      <div className="circle">
        <div
          className="mask full"
          style={{
            transform: `rotate(${1.8 * percentage}deg)`,
          }}
        >
          <div
            className="fill"
            style={{
              transform: `rotate(${1.8 * percentage}deg)`,
            }}
          ></div>
        </div>

        <div className="mask half">
          <div
            className="fill"
            style={{
              transform: `rotate(${1.8 * percentage}deg)`,
            }}
          ></div>
        </div>

        <div className="inside-circle">{children}</div>
      </div>
    </div>
  );
}

export default PlayerCard;
