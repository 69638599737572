import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import PlayerCard from "../components/PlayerCard/PlayerCard";

function PlayerDetails() {
  const [playerData, setPlayerData] = useState(null);
  const [teamData, setTeamData] = useState(null);
  const { playerSlug } = useParams(); // this should match the route param name
  const location = useLocation();

  useEffect(() => {
    fetchPlayerData();
  }, [playerSlug]);

  const fetchPlayerData = async () => {
    try {
      const response = await fetch("/api/season-players");
      const data = await response.json();
      // assuming you have a function to convert XML to JSON
      const playerFullNameSlug = playerSlug.toLowerCase();

      const player = data.HandballDocument.players.player.find((p) => {
        // Normalize first and last names to lower case
        const firstNameLower = p.firstname.toLowerCase();
        const lastNameLower = p.lastname.toLowerCase();

        // Check if the slug contains either combination of first and last name
        return (
          playerFullNameSlug === `${firstNameLower}-${lastNameLower}` ||
          playerFullNameSlug === `${lastNameLower}-${firstNameLower}`
        );
      });

      // Extract the gif from the URL
      const queryParams = new URLSearchParams(location.search);
      const gif = queryParams.get("gif");

      if (gif && player) {
        player.picture = gif;
      }

      setPlayerData(player);
      setTeamData(data.HandballDocument.team.stats);
    } catch (error) {
      console.error("Failed to fetch player data:", error);
    }
  };

  if (!playerData) {
    return <div>Loading player data...</div>;
  }

  return <PlayerCard player={playerData} teamStats={teamData} />;
}

export default PlayerDetails;
