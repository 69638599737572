import React, { useEffect, useState } from "react";

function LiveChannel() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("/api/last-game")
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Failed to fetch data:", error));
  }, []);

  return (
    <div>
      {data ? (
        <div>
          {/* Display data as needed */}
          <h1>Last Game Details</h1>
          <p>{JSON.stringify(data)}</p> {/* Example display */}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default LiveChannel;
