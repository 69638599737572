import React from "react";
import "./PastGame.css";

function PastGame({ data }) {
  const hasLogo =
    data.tvLogo && data.tvLogo !== "https://www.lnh.fr/medias/televisions/";

  const dayName = data.dayName.trim().replace("Journée ", "J");
  return (
    <div className="past-game-container">
      <div className="game-container">
        <div
          className={`competition ${(hasLogo || data.dateSmall) && "competition-spaced"}`}
        >
          <div>
            {data.competitionName.trim()} - {dayName}
            {data.dateSmall && data.hour && (
              <div className="date-time">
                {data.dateSmall.trim()} {data.hour}
              </div>
            )}
          </div>
          {hasLogo && (
            <div className="tv-channel">
              <img
                src={data.tvLogo}
                alt={data.tvName.trim()}
                className="tv-logo"
              />
            </div>
          )}
        </div>
        <div className="teams-container">
          <div className="team">
            <img
              src={data.teamHomeLogo}
              alt={data.teamHomeName.trim()}
              className="team-logo"
            />
            <div className="team-name">{data.teamHomeName.trim()}</div>
          </div>

          <div className="score">
            {data.scoreHome || data.teamHomeScore} -{" "}
            {data.scoreAway || data.teamAwayScore}
          </div>

          <div className="team">
            <img
              src={data.teamAwayLogo}
              alt={data.teamAwayName.trim()}
              className="team-logo"
            />
            <div className="team-name">{data.teamAwayName.trim()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PastGame;
