import React from "react";
import "./ComingGame.css";

function ComingGame({ data }) {
  const hasLogo =
    data.tvLogo && data.tvLogo !== "https://www.lnh.fr/medias/televisions/";

  const dayName = data.dayName.trim().replace("Journée ", "J");

  return (
    <div className="coming-game-container">
      <div className="game-container">
        <div className="competition">
          {data.competitionName.trim()} - {dayName}
          <div className="tv-channel">
            {hasLogo && (
              <img
                src={data.tvLogo}
                alt={data.tvName.trim()}
                className="tv-logo"
              />
            )}
          </div>
        </div>
        <div className="date-time">
          {data.dateSmall.trim()} à {data.hour}
        </div>
        <div className="stadium">
          {data.stadiumName.trim()} - {data.stadiumCity.trim()}
        </div>
        <div className="teams-container">
          <div className="team">
            <img
              src={data.teamHomeLogo}
              alt={data.teamHomeName.trim()}
              className="team-logo"
            />
            <div className="team-name">{data.teamHomeName.trim()}</div>
          </div>
          <div className="score">VS</div>{" "}
          {/* Since the game has not occurred yet */}
          <div className="team">
            <img
              src={data.teamAwayLogo}
              alt={data.teamAwayName.trim()}
              className="team-logo"
            />
            <div className="team-name">{data.teamAwayName.trim()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComingGame;
