import React, { useEffect, useState } from "react";
import PastGame from "../components/PastGame/PastGame";
import ComingGame from "../components/ComingGame/ComingGame";
import "./SeasonCalendar.css";

function SeasonCalendar() {
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    fetchAndParseXML("/api/season-calendar");
  }, []);

  const fetchAndParseXML = async (url) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      setMatches(data.HandballDocument.calendar.item);
    } catch (error) {
      console.error("Failed to fetch or parse XML:", error);
    }
  };

  // Assuming that matchReporte indicates whether the match has been played
  const isPastGame = (match) =>
    parseInt(match.teamHomeScore) > 0 || parseInt(match.teamAwayScore) > 0;

  return (
    <div className="season-calendar-container">
      {matches.length > 0 ? (
        matches.map((match, index) => {
          return isPastGame(match) ? (
            <PastGame key={index} data={match} />
          ) : (
            <ComingGame key={index} data={match} />
          );
        })
      ) : (
        <p>Loading season calendar or data not available...</p>
      )}
    </div>
  );
}

export default SeasonCalendar;
