import React from "react";
import "./TeamSeason.css"; // Ensure CSS is setup to match your layout design

function TeamSeason({ team, isAix }) {
  return (
    <div className={`team-row ${isAix && "aix-team"}`}>
      <div className="team-rank">{team.rank}</div>
      <div className="team-logo">
        <img src={team.teamLogo} alt={team.teamName.trim()} />
      </div>
      <div className="team-name">{team.teamName.trim()}</div>
      <div className="team-points">{team.pointsTotal}</div>
      <div className="team-matches">{team.gamesTotal}</div>
      <div className="team-victories">{team.gamesVictories}</div>
      <div className="team-draws">{team.gamesTies}</div>
      <div className="team-defeats">{team.gamesLosses}</div>
      <div className="team-goals-for">{team.goalsTotal}</div>
      <div className="team-goals-against">{team.goalsAgainstTotal}</div>
      <div className="team-goal-diff">
        {team.goalsTotal - team.goalsAgainstTotal}
      </div>
      {/*<div className="team-part-pts">{team.pointsTotal}</div>*/}
      {/*<div className="team-part-goals">/!* Logic for participant goals *!/</div>*/}
    </div>
  );
}

export default TeamSeason;
