import React from "react";
import "./ReloadButton.css";

function ReloadButton({ showReload, handleReload }) {
  return (
    <div className="reload-container">
      <p>Loading next game data or data not available...</p>
      {showReload && (
        <button onClick={handleReload} className="reload-button">
          Reload Data
        </button>
      )}
    </div>
  );
}

export default ReloadButton;
