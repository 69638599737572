import React from "react";
import "./PlayerCard.css";
import Percentage from "../Percentage/Percentage"; // Make sure to create this CSS file for styling

const positionImages = {
  Gardien: "/assets/positions/gardien.png",
  "Ailier gauche": "/assets/positions/ailier-gauche.png",
  "Ailier droit": "/assets/positions/ailier-droit.png",
  "Arrière gauche": "/assets/positions/arriere-gauche.png",
  "Arrière droit": "/assets/positions/arriere-droit.png",
  "Demi-centre": "/assets/positions/demi-centre.png",
  Pivot: "/assets/positions/pivot.png",
};

function PlayerCard({ player, teamStats }) {
  // Calculate player's success rate
  const successRate =
    player.stats.nbPlayed > 0
      ? ((player.stats.nbPlayed / teamStats.nbPlayed) * 100).toFixed(0)
      : 0;

  const stopPerMatch =
    player.stats.goalkeeperStops > 0
      ? (player.stats.goalkeeperStops / player.stats.nbPlayed).toFixed(0)
      : 0;

  function calculateAge(birthdate) {
    // Split the birthdate string by '/' to get day, month, and year
    const [day, month, year] = birthdate.split("/");

    // Create a new date object for the birth date
    const birthDate = new Date(year, month - 1, day); // month is 0-indexed in JS Date

    // Get the current date
    const currentDate = new Date();

    // Calculate the age
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const m = currentDate.getMonth() - birthDate.getMonth();

    // If the current month is before the birth month, or it's the birth month but the day is yet to come, subtract one year from the age
    if (m < 0 || (m === 0 && currentDate.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  return (
    <div className="player-card">
      <div className="player-info">
        <h2 className="player-name">
          {player.firstname} {player.lastname.toUpperCase()}
        </h2>
        <h3 className="player-position">
          <span className="player-number">{player.number}</span>{" "}
          {player.positionName}
        </h3>
        <div className="player-container-details">
          <div className="player-position-img">
            <img
              src={positionImages[player.positionName]}
              alt={player.positionName}
            />
          </div>
          <div className="player-details">
            <div className="player-details-left-column">
              <p>Nationalité:</p>
              <p>Âge: </p>
              <p>Taille: </p>
              <p>Poids: </p>
            </div>
            <div className="player-details-right-column">
              <p>{player.countriesName}</p>
              <p>{calculateAge(player.birthdateSmall)} ans</p>
              <p>{player.height} cm</p>
              <p>{player.weight} kg</p>
            </div>
          </div>
        </div>
        <div
          className="player-photo"
          style={{
            backgroundImage: "url(/assets/playerBackground.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <img
            src={player.gif ?? player.picture}
            alt={`${player.firstname} ${player.lastname}`}
          />
        </div>
      </div>
      <div className="player-stats">
        <div className="stat">
          <div className="stat-label">MATCHS JOUÉS</div>
          <Percentage
            percentage={(player.stats.nbPlayed / teamStats.nbPlayed) * 100}
          >
            <div className="stat-value">
              {player.stats.nbPlayed}
              <span>/ {teamStats.nbPlayed}</span>
            </div>
          </Percentage>
        </div>
        <div className="stat">
          <div className="stat-label">RÉUSSITE</div>
          <Percentage
            percentage={(player.stats.nbPlayed / teamStats.nbPlayed) * 100}
          >
            <div className="stat-value">
              {successRate}
              <span>%</span>
            </div>
          </Percentage>
        </div>
        <div className="stat">
          <div className="stat-label">TOTAL ARRÊTS</div>
          <div className="stat-value">{player.stats.goalkeeperStops}</div>
        </div>
        <div className="stat">
          <div className="stat-label">ARRÊTS / MATCH</div>
          <div className="stat-value">{stopPerMatch}</div>
        </div>
        <div className="stat">
          <div className="stat-label">NOMBRE DE BUTS</div>
          <div className="stat-value">{player.stats.playerGoals}</div>
        </div>
        <div className="stat">
          <div className="stat-label">AVERTISSEMENT</div>
          <div className="stat-value">{player.stats.nbEjected}</div>
        </div>
        <div className="stat">
          <div className="stat-label">2 MIN</div>
          <div className="stat-value">{player.stats.nbTwoMin}</div>
        </div>
      </div>
    </div>
  );
}

export default PlayerCard;
